var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-4" },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("psi-stats-card", {
                    attrs: {
                      "color-option": 1,
                      stat: "50 APPS",
                      title: "Pending Applications",
                      "sub-title": "March 2020",
                      icon: "mdi mdi-timer-sand"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("psi-stats-card", {
                    attrs: {
                      "color-option": 2,
                      stat: "75 APPS",
                      title: "Imported Applications",
                      "sub-title": "Aug 3 - Aug 10",
                      icon: "mdi mdi-file-import"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "actions",
                        fn: function() {
                          return [
                            _c("v-btn", { attrs: { color: "primary" } }, [
                              _vm._v("View All Pending")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4" } },
                [
                  _c("psi-stats-card", {
                    attrs: {
                      "color-option": 3,
                      stat: "25 APPS",
                      title: "Processed Applications",
                      "sub-title": "Aug 3 - Aug 10",
                      icon: "mdi mdi-clipboard-check"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "actions",
                        fn: function() {
                          return [
                            _c("v-btn", { attrs: { color: "primary" } }, [
                              _vm._v("View All Pending")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }