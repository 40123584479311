<template>
    <v-card class="mt-4">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <psi-stats-card
                        :color-option="1"
                        stat="50 APPS"
                        title="Pending Applications"
                        sub-title="March 2020"
                        icon="mdi mdi-timer-sand"
                    ></psi-stats-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <psi-stats-card
                        :color-option="2"
                        stat="75 APPS"
                        title="Imported Applications"
                        sub-title="Aug 3 - Aug 10"
                        icon="mdi mdi-file-import"
                    >
                        <template v-slot:actions>
                            <v-btn color="primary">View All Pending</v-btn>
                        </template>
                    </psi-stats-card>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <psi-stats-card
                        :color-option="3"
                        stat="25 APPS"
                        title="Processed Applications"
                        sub-title="Aug 3 - Aug 10"
                        icon="mdi mdi-clipboard-check"
                    >
                        <template v-slot:actions>
                            <v-btn color="primary">View All Pending</v-btn>
                        </template>
                    </psi-stats-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "application-uploads-stats",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters("Uploads", ["uploads"]),
    },
    watch: {},
    created() {},
    mounted() {
        this.$store.dispatch("Uploads/getUploads");
    },
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>